
import type {UserModule} from '@/types'
import VueGtag from 'vue-gtag-next'

export const install: UserModule = ({ app }) => {
    app.use(VueGtag, {
        property:{
            id: import.meta.env.VITE_GA_ID,
            params:{
                send_page_view: true
            }
        }
    })
};